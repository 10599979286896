html,
body,
#root {
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
}
@media (min-width: 1000px) {
  .container {
    width: 1000px;
  }
}

* {
  font-family: 'Arimo', sans-serif;
}

#root > div {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#loadingOverlay {
  width: 100%;
  height: 100%;
  background-color: white;
  position: absolute;
  z-index: 2;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__suggestion-match {
  color: red;
  font-weight: bold;
}

.cnt-button {
  position: relative;
}

.cnt-button button {
  position: absolute;
  top: 50%;
  transform: translateY(100%);
}

/* react-virtualized-select/styles.css */
.VirtualSelectGrid {
  z-index: 1;
}

.VirtualizedSelectOption {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0.5rem;
  cursor: pointer;
}
.VirtualizedSelectFocusedOption {
  background-color: rgba(0, 126, 255, 0.1);
}
.VirtualizedSelectDisabledOption {
  opacity: 0.5;
}
.VirtualizedSelectSelectedOption {
  font-weight: bold;
}
/* end react-virtualized-select */

/* vertical align fix for select clear button */
.Select-clear-zone .Select-clear {
  position: relative;
  top: 1px;
}

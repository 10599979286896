.table-review td {
  width: 40%;
}

.table-review th:first-child {
  padding-left: 0;
}

.table-review td:first-child {
  padding-left: 0;
  width: 20%;
}

.translation__mfe-heading {
  font-size: 1.8rem;
  font-weight: bold;
  padding: 1rem 2rem;
  margin: 0;
  cursor: pointer;
}

.translation__mfe-heading:hover,
.translation__mfe-property:hover {
  background-color: rgb(238, 238, 238);
}

.translation .translation__mfe-section {
  margin-bottom: 2rem;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 0px 0px,
    rgb(0 0 0 / 19%) 0px 0px 10px 0px;
}

.translation__mfe-property {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 1rem 2rem;
  font-size: 1.6rem;
}

/* Loader */
.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
